/* eslint-disable import/no-named-as-default-member */
import { combineReducers } from 'redux';

import routing from './routing';
import timeline from './timeline';
import global from './global';
import banner from './banner';
import i18n from './i18n';
import news from './news';
import business from './business';

const reducers = combineReducers({
	...routing.reducer,
	...timeline.reducer,
	...global.reducer,
	...banner.reducer,
	...i18n.reducer,
	...news.reducer,
	...business.reducer,
});

export default reducers;
