import React from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import styles from './index.css';

const Navigation = ({ className, isTheme }) => {
	const { t } = useTranslation();

	return (
		<nav
			className={classnames(styles.navigation, className, {
				[styles.theme]: isTheme,
			})}
		>
			<ul>
				<li>
					<a href="#BUSINESS" data-element="nav-link">
						{t('navigation.BUSINESS')}
					</a>
				</li>
				<li>
					<a href="#CATEGORY" data-element="nav-link">
						{t('navigation.CATEGORY')}
					</a>
				</li>
				<li>
					<a href="#ANNOUNCEMENT" data-element="nav-link">
						{t('navigation.ANNOUNCEMENT')}
					</a>
				</li>
				<li>
					<a href="#ABOUT" data-element="nav-link">
						{t('navigation.ABOUT')}
					</a>
				</li>
				<li>
					<a href="#HISTORY" data-element="nav-link">
						{t('navigation.HISTORY')}
					</a>
				</li>
				<li>
					<a href="#NEWS" data-element="nav-link">
						{t('navigation.NEWS')}
					</a>
				</li>
			</ul>
		</nav>
	);
};

export default Navigation;
