import 'whatwg-fetch';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

// A modern alternative to CSS resets
import 'normalize.css';
// Global css setting
import './global.css';
// init youtube api
import initYoutubeIframeAPI from 'util/youtube';
import { addResizeListener, addMatchMediaListener } from 'util/event';
// import i18n (needs to be bundled)
import 'util/i18n';

import Router from 'layouts/Router';

import configureStore from './store';
import history from './store/history';
import routes from './routes';

const store = configureStore({});
initYoutubeIframeAPI();
addResizeListener(store);
addMatchMediaListener(store);

ReactDOM.render(
	<Provider store={store}>
		<Router history={history} routes={routes} store={store} />
	</Provider>,
	document.getElementById('content'),
);
