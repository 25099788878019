import { createAction, handleActions } from 'redux-actions';
import i18n from 'i18next';
import { useRedux } from 'util/hook/redux';
import history from 'store/history';

export const languageChange = createAction('LANGUAGE_CHANGED', lng => {
	i18n.changeLanguage(lng);
	history.push(`/${lng}`);
	document.body.setAttribute('lan', lng);
	return { lng };
});

const reducer = {
	i18n: handleActions(
		{
			LANGUAGE_CHANGED: (state, action) => ({
				...state,
				lng: action.payload.lng,
			}),
		},
		{
			lng: 'tw',
		},
	),
};

const mapHooksToState = state => state.i18n;
/* eslint-disable react-hooks/rules-of-hooks */
export const usei18n = () => useRedux(mapHooksToState, { languageChange });

export default { reducer };
