import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import resources from 'assets/locales';

i18n
	// detect user language
	// learn more: https://github.com/i18next/i18next-browser-languageDetector
	.use(LanguageDetector)
	// pass the i18n instance to react-i18next and bind react-i18next to the instance.
	.use(initReactI18next)
	// init i18next
	// for all options read: https://www.i18next.com/overview/configuration-options
	.init({
		resources,
		fallbackLng: 'tw',
		lng: 'tw',
		debug: false,
		interpolation: {
			escapeValue: false, // not needed for react as it escapes by default
		},
		// keySeparator: false, // we do not use keys in form messages.welcome
		// react i18next special options (optional)
		// override if needed - omit if ok with defaults
		/*
		react: {
			bindI18n: 'languageChanged', // which events trigger a rerender, can be set to false or string of events separated by ""
			bindI18nStore: '', // define which events on resourceStore should trigger a rerender
			transEmptyNodeValue: '', // how to treat failed lookups in Trans component
			transSupportBasicHtmlNodes: true, // convert eg. <br/> found in translations to a react component of type br
			transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'p'], // Which nodes not to convert in defaultValue generation in the Trans component.
			useSuspense: true, // If using Suspense or not
		}
		*/
	});
export default i18n;
