import React from 'react';

import { useHistory } from 'models/routing';

const onClickHandler = (callback, history) => {
	return e => {
		e.preventDefault();
		history.push(e.currentTarget.pathname);
		callback(e);
	};
};

const Link = ({ className, to, onClick = () => {}, children }) => {
	const history = useHistory();
	const type = to.indexOf('https://') === -1 ? 'internal' : 'external';

	if (type === 'internal') {
		if (to.indexOf('#') !== -1) {
			return (
				<a className={className} href={to} role="button" tabIndex={0} onKeyPress={() => {}}>
					{children}
				</a>
			);
		}
		return (
			<a
				className={className}
				href={to}
				role="button"
				tabIndex={0}
				onClick={onClickHandler(onClick, history)}
				onKeyPress={() => {}}
			>
				{children}
			</a>
		);
	}

	return (
		<a className={className} href={to} target="_blank" rel="noopener noreferrer">
			{children}
		</a>
	);
};

export default Link;
