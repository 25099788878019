import { createAction, handleActions } from 'redux-actions';
import { useRedux } from 'util/hook/redux';

export const matchMedia = createAction('MATCH_MEDIA', media => ({ media }));

export const resizeWindow = createAction('RESIZE_WINDOW', (width, height) => ({ width, height }));

export const scrollWindow = createAction('SCROLL_WINDOW', scrollY => ({ scrollY }));

const reducer = {
	global: handleActions(
		{
			MATCH_MEDIA: (state, action) => ({
				...state,
				media: action.payload.media,
			}),

			RESIZE_WINDOW: (state, action) => ({
				...state,
				width: action.payload.width,
				height: action.payload.height,
			}),

			SCROLL_WINDOW: (state, action) => ({
				...state,
				scrollY: action.payload.scrollY,
			}),
		},
		{
			media: '',
			width: document.documentElement.clientWidth,
			height: document.documentElement.clientHeight,
			scrollY: window.scrollY,
		},
	),
};

const mapHooksToState = state => state.global;

export const useGlobal = () => useRedux(mapHooksToState, { matchMedia, resizeWindow });

export default { reducer };
