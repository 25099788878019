import React from 'react';

import { getBanner } from 'models/banner';
import { getBusiness } from 'models/business';
import { getTimeline } from 'models/timeline';
import { languageChange } from 'models/i18n';
import { getNews } from 'models/news';

const routes = {
	path: '',
	components: () => [],
	render: (_, children) => children,
	onEnter: async ({ next, store }) => {
		// console.log('on Enter Root');
		const children = await next();
		store.dispatch(getBanner());
		store.dispatch(getBusiness());
		store.dispatch(getTimeline());
		store.dispatch(getNews());
		// console.log('on Enter Root / end');

		return children;
	},
	children: [
		{
			path: '/:lang',
			components: () => [import(/* webpackChunkName: 'home' */ './Home')],
			render: ([Home]) => <Home />,
			onEnter: async ({ next, store, params: { lang } }) => {
				// console.log('on Enter Home with language params');
				const children = await next();
				// console.log('on Enter Home with language params / end');
				store.dispatch(languageChange(lang));
				return children;
			},
		},
		{
			path: '/',
			components: () => [import(/* webpackChunkName: 'home' */ './Home')],
			render: ([Home]) => <Home />,
			onEnter: async ({ next, store }) => {
				// console.log('on Enter Home');
				const children = await next();
				// console.log('on Enter Home / end');
				store.dispatch(languageChange('tw'));
				return children;
			},
		},
	],
};

export default routes;
