import { createAction, handleActions } from 'redux-actions';
import { useRedux } from 'util/hook/redux';
import { wrapFetch } from 'util/api';

export const getBusiness = createAction('FETCH_BUSINESS', () => async (dispatch, getState) => {
	const {
		i18n: { lng },
	} = getState();

	try {
		const { status, message, data } = await wrapFetch(
			'backstage/api/web/businessArea',
			{
				method: 'GET',
			},
			{
				language: lng,
			},
		);

		if (status !== 200) {
			throw new Error(message);
		}

		const business = data.map(item => item);

		return { business };
	} catch (err) {
		throw new Error(err);
	}
});

const reducer = {
	business: handleActions(
		{
			FETCH_BUSINESS_PENDING: state => ({
				...state,
				loading: true,
				error: false,
			}),

			FETCH_BUSINESS_FULFILLED: (state, action) => ({
				...state,
				loading: false,
				business: action.payload.business,
			}),

			FETCH_BUSINESS_REJECTED: state => ({
				...state,
				loading: false,
				error: true,
			}),
		},
		{
			business: [],
			loading: false,
			error: false,
		},
	),
};

const mapHooksToState = state => state.business;

export const useBusiness = () => useRedux(mapHooksToState, { getBusiness });

export default { reducer };
