import { createAction, handleActions } from 'redux-actions';
import { useRedux } from 'util/hook/redux';
import { wrapFetch } from 'util/api';

export const getBanner = createAction('FETCH_BANNER', () => async (dispatch, getState) => {
	const {
		global: { media },
	} = getState();

	try {
		const { status, message, data } = await wrapFetch('backstage/api/web/banner', {
			method: 'GET',
		});

		if (status !== 200) {
			throw new Error(message);
		}

		const banners = data.map(item => {
			let content;

			if (item.youtube_url && media !== 'phone') {
				content = item.youtube_url;
			} else if (media === 'phone') {
				content = item.mobile_thumbnail;
			} else {
				content = item.pc_thumbnail;
			}

			return {
				id: item.id,
				content,
				title: item.title,
				url: item.url,
			};
		});

		return { banners };
	} catch (err) {
		throw new Error(err);
	}
});

const reducer = {
	banner: handleActions(
		{
			FETCH_BANNER_PENDING: state => ({
				...state,
				loading: true,
				error: false,
			}),

			FETCH_BANNER_FULFILLED: (state, action) => ({
				...state,
				loading: false,
				error: true,
				banners: action.payload.banners,
			}),

			FETCH_BANNER_REJECTED: state => ({
				...state,
				loading: false,
				error: true,
			}),
		},
		{
			banners: [],
			loading: false,
			error: false,
		},
	),
};

const mapHooksToState = state => state.banner;

export const useBanner = () => useRedux(mapHooksToState, { getBanner });

export default { reducer };
