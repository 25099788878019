/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import classnames from 'classnames';

import Navigation from 'components/molecules/Navigation';
import LangSwitch from 'components/atoms/LangSwitch';
import MobileNavigation from 'components/organisms/MobileNavigation';

import { useRouting } from 'models/routing';
import { usei18n } from 'models/i18n';
import { useGlobal } from 'models/global';

import Logo from 'images/logo/logo.inline.svg';
import LogoColor from 'images/logo/logo_ci.inline.svg';
import LogoMob from 'images/logo/logo_m.inline.svg';
import LogoColorMob from 'images/logo/logo_ci_m.inline.svg';
import styles from './index.css';

const langaugeList = [
	['tw', 'zh-TW'],
	['en', 'en-US'],
	['jp', 'ja-JP'],
];

const Header = ({ className, fade }) => {
	const [{ pathname }] = useRouting();
	const [, { languageChange }] = usei18n();
	const [{ media }] = useGlobal();
	const [isMobNavOpen, setIsMobNavOpen] = useState(false);

	const currentLang =
		// eslint-disable-next-line no-nested-ternary
		pathname !== undefined ? (pathname !== '/' ? pathname.split('/').pop() : 'tw') : 'tw';

	const handleMobNavClick = () => {
		setIsMobNavOpen(!isMobNavOpen);
	};

	const handleChangeLang = lng => languageChange(lng);

	return (
		<div
			className={classnames(styles.header, styles.open, className, {
				[styles.fill]: fade,
			})}
		>
			<a href="/" className={styles.logo}>
				{media === 'phone' ? (
					fade ? (
						<LogoColorMob className={styles.logo} />
					) : (
						<LogoMob className={styles.logo} />
					)
				) : fade ? (
					<LogoColor className={styles.logo} />
				) : (
					<Logo className={styles.logo} />
				)}
			</a>
			<Navigation isTheme={fade} />
			<LangSwitch
				langaugeList={langaugeList}
				route={currentLang}
				isTheme={fade}
				onChangeLang={handleChangeLang}
			/>
			<div
				className={styles.hamburgerIcon}
				onClick={handleMobNavClick}
				onKeyUp={() => {}}
				role="button"
				tabIndex={0}
			>
				<span className={classnames(styles.hamburgerBar, { [styles.hamburgerBarTheme]: fade })} />
				<span className={classnames(styles.hamburgerBar, { [styles.hamburgerBarTheme]: fade })} />
				<span className={classnames(styles.hamburgerBar, { [styles.hamburgerBarTheme]: fade })} />
			</div>
			<MobileNavigation
				handleClick={handleMobNavClick}
				className={classnames({ [styles.mobNavOpen]: isMobNavOpen })}
			/>
		</div>
	);
};

export default Header;
