import { createAction, handleActions } from 'redux-actions';
import { useRedux } from 'util/hook/redux';
import { wrapFetch } from 'util/api';

export const getTimeline = createAction('FETCH_TIMELINE', () => async (_, getState) => {
	const {
		global: { media },
		i18n: { lng },
	} = getState();

	try {
		const { status, message, data } = await wrapFetch(
			'backstage/api/web/timeline',
			{
				method: 'GET',
			},
			{
				language: lng,
			},
		);

		if (status !== 200) {
			throw new Error(message);
		}

		const yearDataObj = { ...data };
		const yearDataArr = Object.keys(yearDataObj);

		const eventDataObj = {};
		// eslint-disable-next-line no-restricted-syntax
		for (const year of Object.entries(data)) {
			year[1].forEach((event, idx) => {
				eventDataObj[`${year[0]}-${idx + 1}`] = {
					name: event.business_body,
					context: event.description,
					image: event.image,
				};
			});
		}
		const eventDataArr = Object.keys(eventDataObj);

		const yearDisplay = media === 'phone' ? yearDataArr.slice(0, 4) : yearDataArr.slice(0, 10);
		const currentEventIdIndex = media === 'phone' ? 2 : 1;
		const currentEventId = media === 'phone' ? eventDataArr[2] : eventDataArr[1];

		return {
			yearDataObj,
			yearDataArr,
			eventDataObj,
			eventDataArr,
			yearDisplay,
			currentEventId,
			currentEventIdIndex,
		};
	} catch (err) {
		throw new Error(err);
	}
});

export const counterChange = createAction('COUNTER_CHANGE', nextEventId => async (_, getState) => {
	const {
		timeline: { currentEventId, eventDataArr, yearDataArr, yearDisplay },
		global: { media },
	} = getState();

	const nextEventYear = nextEventId.split('-')[0];
	const currentEventYear = currentEventId.split('-')[0];
	const nextEventIdIndex = eventDataArr.findIndex(eventId => eventId === nextEventId);
	const nextEventYearIndex = yearDataArr.findIndex(year => year === nextEventYear);
	let newYearDisplay;

	if (nextEventYear === currentEventYear) {
		// 當下個事件年份與目前相同，只要改變 currentEventId 即可， yearDisplay 不變。
		return { currentEventId: nextEventId, currentEventIdIndex: nextEventIdIndex, yearDisplay };
	}

	if (nextEventYear > currentEventYear) {
		if (media === 'phone') {
			switch (nextEventYear) {
				case yearDataArr[1]:
					// 手機裝置上，往右點到 1979 年（第二筆年份）時，補上 1 筆假資料。
					newYearDisplay = [
						'',
						...yearDataArr.slice(nextEventYearIndex - 1, nextEventYearIndex + 2),
					];
					break;
				default:
					// 手機裝置上，只會顯示 4 個點，且聚焦在左邊數來第 3 個點上。
					newYearDisplay = yearDataArr.slice(nextEventYearIndex - 2, nextEventYearIndex + 2);
			}
		} else {
			// 一般網頁上，會顯示 10 個點，且聚焦在左邊數來第 2 個點上。
			newYearDisplay = yearDataArr.slice(nextEventYearIndex - 1, nextEventYearIndex + 9);
		}
	}

	if (nextEventYear < currentEventYear) {
		if (media === 'phone') {
			switch (nextEventYear) {
				case yearDataArr[1]:
					// 手機裝置上，往左點到 1979 年（第二筆年份）時，補上 1 筆假資料。
					newYearDisplay = [
						'',
						...yearDataArr.slice(nextEventYearIndex - 1, nextEventYearIndex + 2),
					];
					break;
				case yearDataArr[0]:
					// 手機裝置上，往左點到 1970 年（第一筆年份）時，補上 2 筆假資料。
					newYearDisplay = [
						'',
						'',
						...yearDataArr.slice(nextEventYearIndex, nextEventYearIndex + 2),
					];
					break;
				default:
					newYearDisplay = yearDataArr.slice(nextEventYearIndex - 2, nextEventYearIndex + 2);
			}
		} else {
			switch (nextEventYear) {
				case yearDataArr[0]:
					// 一般網頁上，往左點到 1970 年（第一筆年份）時，補上 1 筆假資料。
					newYearDisplay = ['', ...yearDataArr.slice(nextEventYearIndex, nextEventYearIndex + 8)];
					break;
				default:
					newYearDisplay = yearDataArr.slice(nextEventYearIndex - 1, nextEventYearIndex + 9);
			}
		}
	}

	return {
		currentEventId: nextEventId,
		currentEventIdIndex: nextEventIdIndex,
		yearDisplay: newYearDisplay,
	};
});

const reducer = {
	timeline: handleActions(
		{
			FETCH_TIMELINE_FULFILLED: (state, action) => {
				return {
					...state,
					...action.payload,
				};
			},
			COUNTER_CHANGE_FULFILLED: (state, action) => {
				const {
					payload: { currentEventId, currentEventIdIndex, yearDisplay },
				} = action;

				return {
					...state,
					currentEventId,
					currentEventIdIndex,
					yearDisplay,
				};
			},
		},
		{
			yearDataObj: {},
			yearDataArr: [],
			eventDataObj: {},
			eventDataArr: [],
			yearDisplay: [],
			currentEventId: '',
			currentEventIdIndex: null,
			nextEventId: null,
		},
	),
};

const mapHooksToState = state => state.timeline;

export const useTimeline = () =>
	useRedux(mapHooksToState, {
		getTimeline,
		counterChange,
	});

export default { reducer };
