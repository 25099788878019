import React from 'react';
import classnames from 'classnames';

import styles from './index.css';

const LangSwitch = ({ className, langaugeList, route, isTheme, onChangeLang }) => (
	<div
		className={classnames(styles.langSwitch, className, {
			[styles.theme]: isTheme,
		})}
	>
		{langaugeList.map(lang => (
			<span
				className={classnames(styles.wording, {
					[styles.active]: lang[0] === route,
				})}
				key={lang[0]}
				role="button"
				tabIndex={0}
				onClick={() => onChangeLang(lang[0])}
				onKeyPress={() => {}}
			>
				{lang[0]}
			</span>
		))}
	</div>
);

export default LangSwitch;
