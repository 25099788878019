import { createAction, handleActions } from 'redux-actions';
import { useRedux } from 'util/hook/redux';
import { wrapFetch } from 'util/api';

export const getNews = createAction('FETCH_NEWS', () => async () => {
	try {
		const { status, message, data } = await wrapFetch('backstage/api/web/news', {
			method: 'GET',
		});

		if (status !== 200) {
			throw new Error(message);
		}

		const dataKeyArr = Object.keys(data);
		let newsAll = [];
		dataKeyArr.forEach(key => {
			if (key === 'all') return;
			const newsFromType = data[key].map(item => {
				const dateStr = new Date(item.date_show).toDateString();
				const dateArr = dateStr.split(' ');
				const dateShow = `${dateArr[2]} ${dateArr[1]}, ${dateArr[3]}`;
				return {
					type: item.tags[0].text,
					url: item.url,
					title: item.title,
					date: dateShow,
				};
			});
			newsAll = [...newsAll, ...newsFromType];
		});

		return { newsAll };
	} catch (err) {
		throw new Error(err);
	}
});

const reducer = {
	news: handleActions(
		{
			FETCH_NEWS_PENDING: state => ({
				...state,
				loading: true,
				error: false,
			}),

			FETCH_NEWS_FULFILLED: (state, action) => ({
				...state,
				loading: false,
				error: true,
				news: [...action.payload.newsAll],
			}),

			FETCH_NEWS_REJECTED: state => ({
				...state,
				loading: false,
				error: true,
			}),
		},
		{
			news: [],
			loading: false,
			error: false,
		},
	),
};

const mapHooksToState = state => state.news;

export const useNews = () => useRedux(mapHooksToState, { getNews });

export default { reducer };
