import { resizeWindow, scrollWindow, matchMedia } from 'models/global';

export const addResizeListener = store => {
	let ticking = false;

	const resizeEvent = () => {
		const { clientWidth, clientHeight } = document.documentElement;

		if (!ticking) {
			window.requestAnimationFrame(() => {
				store.dispatch(resizeWindow(clientWidth, clientHeight));
				ticking = false;
			});
		}
		ticking = true;
	};

	window.addEventListener('resize', resizeEvent);
};

export const addScrollListener = store => {
	let ticking = false;

	const scrollEvent = () => {
		const { scrollY } = window;

		if (!ticking) {
			window.requestAnimationFrame(() => {
				store.dispatch(scrollWindow(scrollY));
				ticking = false;
			});
		}
		ticking = true;
	};

	window.addEventListener('scroll', scrollEvent, false);
};

export const addMatchMediaListener = store => {
	const phoneMedia = window.matchMedia('(max-width: 414px)');
	const smallTabletMedia = window.matchMedia('(max-width: 768px) and (min-width: 415px)');
	const tabletMedia = window.matchMedia('(max-width: 1280px) and (min-width: 769px)');
	const desktopMedia = window.matchMedia('(min-width: 1281px)');

	const handleMediaChange = callback => media => {
		if (media.matches) {
			callback();
		}
	};

	phoneMedia.addListener(handleMediaChange(() => store.dispatch(matchMedia('phone'))));
	smallTabletMedia.addListener(handleMediaChange(() => store.dispatch(matchMedia('small-tablet'))));
	tabletMedia.addListener(handleMediaChange(() => store.dispatch(matchMedia('tablet'))));
	desktopMedia.addListener(handleMediaChange(() => store.dispatch(matchMedia('desktop'))));

	// Initalize
	// handleMediaChange 回傳 phoneMedia，參數帶 () => store.dispatch(matchMedia('phone'))
	// 如果 phoneMedia 的 match 是 true，呼叫 callback 執行 () => store.dispatch(matchMedia('phone'))
	handleMediaChange(() => store.dispatch(matchMedia('phone')))(phoneMedia);
	handleMediaChange(() => store.dispatch(matchMedia('small-tablet')))(tabletMedia);
	handleMediaChange(() => store.dispatch(matchMedia('tablet')))(tabletMedia);
	handleMediaChange(() => store.dispatch(matchMedia('desktop')))(desktopMedia);
};

export const getMeta = (url, callback) => {
	const img = new Image();
	img.src = url;
	img.onload = () => callback(img.width, img.height);
};
