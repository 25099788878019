import React from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import Link from 'components/atoms/Link';

import Logo from 'images/logo/logo.inline.svg';
import FbIcon from 'images/icons/fb.inline.svg';
import IgIcon from 'images/icons/ig.inline.svg';
import InIcon from 'images/icons/in.inline.svg';

import styles from './index.css';

const MobileNavigation = ({ handleClick, className }) => {
	const { t } = useTranslation();

	return (
		<div className={classnames(styles.mobileNavigation, className)}>
			<div className={styles.container}>
				<div className={styles.header}>
					<a href="/">
						<Logo />
						{/* <div className={styles.logo} /> */}
					</a>
					<div
						className={styles.hamburgerIcon}
						onClick={handleClick}
						onKeyUp={() => {}}
						role="button"
						aria-label="Hamburger Icon"
						tabIndex={0}
					/>
				</div>
				<ul className={styles.body}>
					<li>
						<div>
							<p>BUSINESS</p>
							<a onClick={handleClick} href="#BUSINESS">
								{t('navigation.BUSINESS')}
							</a>
						</div>
						<div>
							<p>CATEGORY</p>
							<a onClick={handleClick} href="#CATEGORY">
								{t('navigation.CATEGORY')}
							</a>
						</div>
					</li>
					<li>
						<div>
							<p>HISTORY</p>
							<a onClick={handleClick} href="#HISTORY">
								{t('navigation.HISTORY')}
							</a>
						</div>
						<div>
							<p>ABOUT</p>
							<a onClick={handleClick} href="#ABOUT">
								{t('navigation.ABOUT')}
							</a>
						</div>
					</li>
					<li>
						<div>
							<p>NEWS</p>
							<a onClick={handleClick} href="#NEWS">
								{t('navigation.NEWS')}
							</a>
						</div>
						<div>
							<p>ANNOUNCEMENT</p>
							<a onClick={handleClick} href="#ANNOUNCEMENT">
								{t('navigation.ANNOUNCEMENT')}
							</a>
						</div>
					</li>
				</ul>
				<div className={styles.footer}>
					<hr />
					<div className={styles.soicalLink}>
						<Link to="https://www.facebook.com/yuanyugroup/">
							<FbIcon />
						</Link>
						<Link to="https://www.instagram.com/olympus.plaza.tpe/?igshid=9gl6w5cgeers">
							<IgIcon />
						</Link>
						<Link to="https://www.linkedin.com/company/yuanyu/">
							<InIcon />
						</Link>
					</div>
					<div className={styles.address}>{t('footer.address1')}</div>
					<div className={styles.address}>{t('footer.address2')}</div>
				</div>
			</div>
		</div>
	);
};

export default MobileNavigation;
