import React, { useState, useEffect } from 'react';
import Header from 'components/organisms/Header';

import styles from './index.css';

const App = ({ children }) => {
	const [naviFixed, setNaviFixed] = useState(false);

	const scrollHander = () => {
		if (window.scrollY > 0) {
			setNaviFixed(true);
		} else {
			setNaviFixed(false);
		}
	};

	useEffect(() => {
		window.addEventListener('scroll', scrollHander, { passive: true });

		return () => {
			window.removeEventListener('scroll', scrollHander);
		};
	}, []);

	return (
		<>
			<Header fade={naviFixed} />
			<div className={styles.container}>{children}</div>
		</>
	);
};

export default App;
