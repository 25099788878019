/* eslint-disable no-undef */
const initYoutubeIframeAPI = (youtubeId, index) => {
	/* eslint-disable no-new */
	const onYouTubePlayerAPIReady = ({ height, width, videoId, volume, playerVars }) => {
		new YT.Player(`player-${youtubeId}-${index}`, {
			height,
			width,
			videoId,
			volume,
			playerVars,
		});
	};

	const height = '100%';
	const width = '100%';
	const videoId = youtubeId;
	const volume = 'mute';
	const playerVars = {
		autoplay: 1,
		controls: 0,
		rel: 0,
		modestbranding: 0,
		loop: 1,
		fs: 0,
		ccLoadPolicy: 0,
		ivLoadPolicy: 3,
		autohide: 0,
		mute: 1,
		disablekb: 1,
		playlist: youtubeId,
		start: 0,
	};

	/* eslint-disable no-undef */
	if (typeof YT === 'undefined' || typeof YT.Player === 'undefined') {
		const tag = document.createElement('script');
		tag.src = 'https://www.youtube.com/iframe_api';
		document.body.appendChild(tag);
	} else {
		onYouTubePlayerAPIReady({
			height,
			width,
			videoId,
			volume,
			playerVars,
		});
	}

	// This function creates an <iframe> (and YouTube player) after the API code downloads.
};

export default initYoutubeIframeAPI;
